import {graphql, StaticQuery} from "gatsby"
import React from "react"
import {Container, Menu, MenuItem, Title, Stripes, Number, GotoMenu} from "../styles/sideMenuStyle"
import StripeImg from "../images/Stripes@2x.png"
import {ROUTES} from "../routes";

const SideMenu = () => (
    <Container home>
        <Number>{process.env.GATSBY_PHONE_NUMBER}</Number>
        <Stripes src={StripeImg}/>
        {/*<Title>HEAVY EQUIPMENT RENTALS</Title>
            <Menu>
                <GotoMenu to="/"><MenuItem>Backhoe Rental</MenuItem></GotoMenu>
                <MenuItem>Dozer Rental</MenuItem>
                <MenuItem>Compaction Rental</MenuItem>
                <MenuItem>Excavator Rental</MenuItem>
                <MenuItem>Motor Grader Rental</MenuItem>
                <MenuItem>Motor Scraper Rental</MenuItem>
                <MenuItem>Skid Steer Rental</MenuItem>
                <MenuItem>Skip Loader Rental</MenuItem>
                <MenuItem>Compact Track Loaders Rental</MenuItem>
                <MenuItem>Wheel Loader Rental</MenuItem>
            </Menu>
        <Stripes src={StripeImg}/>*/}
            <Title>HEAVY EQUIPMENT FOR SALE</Title>
            <Menu>
            <GotoMenu to={ROUTES.CURRENT_INVENTORY}><MenuItem>Shop All Equipment</MenuItem></GotoMenu>
            <StaticQuery
            query={graphql`
                query MenuQuery {
                    allTaxonomyTermCategory {
                        edges {
                            node {
                                path {
                                  alias
                                }
                                name
                            }
                        }
                    }
                }
            `}
            render={data => (
                <div>
                {data.allTaxonomyTermCategory.edges.map(({ node }, i) => (
                  <GotoMenu key={i} to={`${node.path.alias}/`}>
                      <MenuItem>{node.name}</MenuItem>
                  </GotoMenu>
                ))}
              </div>
            )}
            />
            </Menu>
        {/*<Stripes src={StripeImg}/>
        <Title>SERVICE &amp; REPAIR</Title>
        <Menu>
            <MenuItem>Hydraulic Ram Rebuilds</MenuItem>
            <MenuItem>Hydraulic System Repair</MenuItem>
            <MenuItem>Equipment Diagnosis</MenuItem>
            <MenuItem>Electrical Diagnosis</MenuItem>
            <MenuItem>Pin and Bushing Replacement</MenuItem>
            <MenuItem>Undercarriage Repair</MenuItem>
            <MenuItem>Paint and Body</MenuItem>
            <MenuItem>Maintenance Services </MenuItem>
            <MenuItem>Attachment Installation</MenuItem>
            <MenuItem>Tires and Tracks</MenuItem>
            <MenuItem>Glass Replacement </MenuItem>
            <MenuItem>Heavy Equipment Cleaning</MenuItem>
                </Menu>*/}
        <Number>{process.env.GATSBY_PHONE_NUMBER}</Number>
    </Container>
  )
  export default SideMenu

