import React from "react"
import {graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import {Container, BlogsCon, Para, Preview, Pic, Title, Goto, Sub} from "../styles/blogStyles"
import SideMenu from "../components/sideMenu"

export default ({ data }) => (
  <Layout>
    <SEO 
    title="Blogs"
    description="Read about the latest information and personal experiences of heavy machinery from SR Affordable Equipment"
    />
    <div  style={{display: "flex", justifyContent: "center"}}>
    <Container>
      <BlogsCon>
        {data.allNodeBlog.edges.map(({ node }) => {
          return (
            <Preview key={node.title}>
              <Title>{node.title}</Title>
              <Sub>{node.relationships.uid.display_name} {node.created}</Sub>
              <Pic src={node.relationships.field_featured_image.url}></Pic>
              <Para>{node.body.summary}<Goto to={`${node.path.alias}/`}> Read More...</Goto></Para>
            </Preview>
          )
        })}
      </BlogsCon>
      <SideMenu/>
    </Container>
    </div>
  </Layout>
)


export const query = graphql`
  query {
    allNodeBlog(sort: {created: DESC}) {
      edges {
        node {
          title
          created(formatString: "MM/DD/YYYY")
          path {
            alias
          }
          body {
            processed
            summary
          }
          field_featured_image {
            alt
            title
          }
          relationships {
            uid {
              display_name
            }
            field_featured_image {
              url
            }
          }
        }
      }
    }
  }
`
