import styled from "styled-components";
import { Link } from "gatsby"

const Container = styled.div`
    display: flex;
    justify-content: center;
    algin-items: center;
    height: auto;
    width: 80%;
    max-width: 1250px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    @media (max-width: 768px) {
      flex-flow: row wrap;
    }
`;

const BlogsCon = styled.div`
    display: flex;
    flex-direction: column;
    justify-self:center;
    width: 75%;
    @media (max-width: 768px) {
      width: 100%;
      margin: 0;
      padding-bottom: 15px;
    }
`;

const Para = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Preview = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  width: 95%;
  border: solid thin #979797;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Pic = styled.img`
  width: 100%;
  object-fit: cover;
  height: 250px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 768px) {
    height: 150px;
    margin-bottom: 5px;
  }
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: bold;
  padding-top: 20px;
  padding-left: 20px;
  @media (max-width: 768px) {
    font-size: 26px;
  }
`;

const Goto = styled(Link)`
  text-decoration: none;
  color: rgb(30,115,190);
`;

const Sub = styled.div`
  font-size: 11px;
  color: #000;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  @media (max-width: 768px) {
    font-size: 10px;
    padding: 5px;
    padding-left: 20px;
  }
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 80%;
    max-width: 1250px;
    @media (max-width: 1160px) {
        width: 100%;
      }
    @media (max-width: 769px) {
      flex-direction: column;
      justify-content: center;
      margin: 10px;
    }
`;

const Article = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
    margin-top: 25px; 
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 10px;
    } 
`;

export {
    Container,
    BlogsCon,
    Article,
    Para, 
    Preview, 
    Pic,
    Title,
    Goto,
    Sub,
    Wrapper,
}